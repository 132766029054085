<template>
  <fragment>
    <div class="main_page">
      <app-navbar></app-navbar>
      <div class="page">
        <loading :active.sync="isPageLoading" is-full-page></loading>
        <div v-if="!isPageLoading">
          <div class="page-title">
            <h1>Users</h1>
          </div>
          <div class="page-content">
            <div class="form-group row">
              <label for="searchKeyword" class="col-sm-2 col-form-label">Search by Keyword</label>
              <div class="col-sm-8">
                <input type="text" id="searchKeyword" name="searchKeyword" class="form-control" autocomplete="off" v-model="searchCriteria.keyword" />
                <small id="passwordHelpBlock" class="form-text text-muted">
                  Default search will check against first/last name; provide @ to instead search against email address.
                </small>
              </div>
              <div class="col-sm-2">
                <button v-if="$permissions().hasEditUserPermission" type="button" class="btn btn-primary" @click="newUser">
                  <i class="fas fa-plus-circle"></i> Add New
                </button>
              </div>
            </div>
            <data-table :data="searchResults.results" :columns="columns" :total="searchResults.total" :query="searchCriteria"
                :isLoading="isDataLoading" :column-filter="false">
              <template v-slot:firstName="{ row }">{{ row.firstName }}</template>
              <template v-slot:lastName="{ row }">{{ row.lastName }}</template>
              <template v-slot:email="{ row }">{{ row.email }}</template>
              <template v-slot:company="{ row }">
                <span v-for="(company, index) in row.companies" :key="'user-search-company-' + index">
                  {{ company.name }} <br />
                </span>
                <span v-if="row.companies.length === 0">---</span>
              </template>
              <template v-slot:edit="{ row }">
                <a href="#" role="button" class="d-flex justify-content-between align-items-center flex-column" title="Edit"
                    v-if="$permissions().hasEditUserPermission" @click.prevent="editUser(row.id)">
                  <img class="mb-1" src="@/assets/document.svg" alt />
                  <span class="small-grey">Edit</span>
                </a>
              </template>
              <template v-slot:delete="{ row }">
                <a href="#" role="button" class="d-flex justify-content-between align-items-center flex-column" title="Delete"
                    v-if="$permissions().hasDeleteUserPermission" @click.prevent="deleteUser(row.id)">
                  <img class="mb-1" src="@/assets/red_x.svg" alt />
                  <span class="small-grey">Delete</span>
                </a>
              </template>
              <template v-slot:loading>
                <loading :active.sync="isDataLoading" :is-full-page="false"></loading>
              </template>
              <template v-slot:no-records>
                <div class="alert alert-warning" role="alert">No records found.</div>
              </template>
            </data-table>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="bv-modal-user" size="lg" hide-header hide-footer :no-close-on-backdrop="true">
      <user @save-action="onUserSave"></user>
    </b-modal>
  </fragment>
</template>

<script>
import { mapActions } from "vuex";
import MessageBox from "@/utils/messagebox";
import Toast from "@/utils/toast";
import { UserService } from "@/services/";
import debounce from "lodash/debounce";

import { DataTable } from "@/components/datatable/";

const defaultSearchCriteria = {
  page: 1,
  pageSize: 20
};

export default {
  name: "configuration-users",
  components: {
    'app-navbar': () => import('@/components/app-navbar.vue'),
    'data-table': DataTable,
    'user': () => import('@/views/configuration/components/user.vue')
  },
  data: function() {
    return {
      isPageLoading: true,
      isDataLoading: false,
      searchResults: { total: 0, results: [] },
      searchCriteria: null,
      columns: [
        { display: "First Name", field: "firstName", sortable: true },
        { display: "Last Name", field: "lastName", sortable: true },
        { display: "Email", field: "email", sortable: false },
        { display: "Company", field: "company", sortable: false },
        { field: 'edit' },
        { field: 'delete' }
      ],
    };
  },
  watch: {
    "searchCriteria.page": function(newValue, oldValue) {
      if (typeof oldValue !== 'undefined' && newValue !== oldValue) {
        this.getSearchResults();
      }
    },
    "searchCriteria.pageSize": function(newValue, oldValue) {
      if (typeof oldValue !== 'undefined' && newValue !== oldValue) {
        this.searchCriteria.page = 1;
        this.getSearchResults();
      }
    },
    "searchCriteria.sortDirection": function(newValue, oldValue) {
      if (typeof oldValue !== 'undefined' && newValue !== oldValue) {
        this.getSearchResults();
      }
    },
    "searchCriteria.sortOrder": function(newValue, oldValue) {
      if (typeof oldValue !== 'undefined' && newValue !== oldValue) {
        this.getSearchResults();
      }
    },
    "searchCriteria.keyword": debounce(function() { this.getSearchResults() }, 500, false)
  },
  methods: {
    newUser() {
      this.resetUser().then(() => {
        this.$bvModal.show("bv-modal-user");
      });
    },
    editUser(id) {
      this.fetchUser(id).then(() => {
        this.$bvModal.show("bv-modal-user");
      });
    },
    async deleteUser(id) {
      let confirm = await MessageBox.confirm(this, "Remove User", "Are you sure you want to remove user?");

      if (confirm) {
        this.removeUser(id).then(() => {
          Toast.success(this, "Successfully removed user!");
        });
      }
    },
    getSearchResults() {
      if (!this.isPageLoading) {
        this.isDataLoading = true;
      }

      return UserService.search(this.searchCriteria).then(response => {
        this.searchResults = response.data;
        this.isDataLoading = false;
      });
    },
    onUserSave () {
      this.getSearchResults();
    },
    ...mapActions([ "fetchRoles", "fetchUser", "fetchUsers", "fetchZones", "removeUser", "resetUser" ])
  },
  beforeMount() {
    this.searchCriteria = { ...defaultSearchCriteria };
  },
  mounted: function() {
    Promise.all([
      this.fetchRoles(),
      this.fetchZones(),
      this.getSearchResults()
    ])
    .then(() => {
        this.isPageLoading = false;
      }
    );
  }
};
</script>
