var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[_c('div',{staticClass:"main_page"},[_c('app-navbar'),_c('div',{staticClass:"page"},[_c('loading',{attrs:{"active":_vm.isPageLoading,"is-full-page":""},on:{"update:active":function($event){_vm.isPageLoading=$event}}}),(!_vm.isPageLoading)?_c('div',[_c('div',{staticClass:"page-title"},[_c('h1',[_vm._v("Users")])]),_c('div',{staticClass:"page-content"},[_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-2 col-form-label",attrs:{"for":"searchKeyword"}},[_vm._v("Search by Keyword")]),_c('div',{staticClass:"col-sm-8"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchCriteria.keyword),expression:"searchCriteria.keyword"}],staticClass:"form-control",attrs:{"type":"text","id":"searchKeyword","name":"searchKeyword","autocomplete":"off"},domProps:{"value":(_vm.searchCriteria.keyword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.searchCriteria, "keyword", $event.target.value)}}}),_c('small',{staticClass:"form-text text-muted",attrs:{"id":"passwordHelpBlock"}},[_vm._v(" Default search will check against first/last name; provide @ to instead search against email address. ")])]),_c('div',{staticClass:"col-sm-2"},[(_vm.$permissions().hasEditUserPermission)?_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":_vm.newUser}},[_c('i',{staticClass:"fas fa-plus-circle"}),_vm._v(" Add New ")]):_vm._e()])]),_c('data-table',{attrs:{"data":_vm.searchResults.results,"columns":_vm.columns,"total":_vm.searchResults.total,"query":_vm.searchCriteria,"isLoading":_vm.isDataLoading,"column-filter":false},scopedSlots:_vm._u([{key:"firstName",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.firstName))]}},{key:"lastName",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.lastName))]}},{key:"email",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.email))]}},{key:"company",fn:function(ref){
var row = ref.row;
return [_vm._l((row.companies),function(company,index){return _c('span',{key:'user-search-company-' + index},[_vm._v(" "+_vm._s(company.name)+" "),_c('br')])}),(row.companies.length === 0)?_c('span',[_vm._v("---")]):_vm._e()]}},{key:"edit",fn:function(ref){
var row = ref.row;
return [(_vm.$permissions().hasEditUserPermission)?_c('a',{staticClass:"d-flex justify-content-between align-items-center flex-column",attrs:{"href":"#","role":"button","title":"Edit"},on:{"click":function($event){$event.preventDefault();return _vm.editUser(row.id)}}},[_c('img',{staticClass:"mb-1",attrs:{"src":require("@/assets/document.svg"),"alt":""}}),_c('span',{staticClass:"small-grey"},[_vm._v("Edit")])]):_vm._e()]}},{key:"delete",fn:function(ref){
var row = ref.row;
return [(_vm.$permissions().hasDeleteUserPermission)?_c('a',{staticClass:"d-flex justify-content-between align-items-center flex-column",attrs:{"href":"#","role":"button","title":"Delete"},on:{"click":function($event){$event.preventDefault();return _vm.deleteUser(row.id)}}},[_c('img',{staticClass:"mb-1",attrs:{"src":require("@/assets/red_x.svg"),"alt":""}}),_c('span',{staticClass:"small-grey"},[_vm._v("Delete")])]):_vm._e()]}},{key:"loading",fn:function(){return [_c('loading',{attrs:{"active":_vm.isDataLoading,"is-full-page":false},on:{"update:active":function($event){_vm.isDataLoading=$event}}})]},proxy:true},{key:"no-records",fn:function(){return [_c('div',{staticClass:"alert alert-warning",attrs:{"role":"alert"}},[_vm._v("No records found.")])]},proxy:true}],null,false,2707520200)})],1)]):_vm._e()],1)],1),_c('b-modal',{attrs:{"id":"bv-modal-user","size":"lg","hide-header":"","hide-footer":"","no-close-on-backdrop":true}},[_c('user',{on:{"save-action":_vm.onUserSave}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }